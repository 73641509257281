<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          @click="goback"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('jilu') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <div>
        <v-card
          flat
          class="my-3 rounded-xl"
        >
          <v-tabs
            v-model="tab_record"
            centered
            show-arrows
          >
            <v-tab
              v-if="showCharge"
              class="text-capitalize"
              :href="`#tabrecord-1`"
            >
              {{ $t('maifen') }}
            </v-tab>
            <v-tab
              v-if="showTrans"
              class="text-capitalize"
              :href="`#tabrecord-2`"
            >
              {{ $t('zhuanru') }}
            </v-tab>
            <v-tab
              v-if="showCash"
              class="text-capitalize"
              :href="`#tabrecord-3`"
            >
              {{ $t('zhuanchu') }}
            </v-tab>
            <v-tab
              v-if="showBetlog"
              class="text-capitalize"
              :href="`#tabrecord-4`"
            >
              {{ $t('youxi') }}
            </v-tab>
            <v-tabs-items
              v-model="tab_record"
            >
              <v-tab-item value="tabrecord-1">
                <v-divider class="opacity-3" />
                <v-card-text class="grey lighten-5 py-2">
                  <v-chip-group
                    mandatory
                    color="orange"
                  >
                    <v-chip
                      small
                      @click="changeDate1(29)"
                    >
                      {{ $t('dates_30') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate1(6)"
                    >
                      {{ $t('dates_7') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate1(0)"
                    >
                      {{ $t('dates_today') }}
                    </v-chip>

                    <v-spacer />

                    <v-chip
                      small
                      @click="select_date1 = !select_date1"
                    >
                      <v-icon x-small>
                        mdi-filter
                      </v-icon>
                      {{ $t('dates_shaixuan') }}
                    </v-chip>
                  </v-chip-group>
                  <div class="text-right text-caption opacity-5">
                    {{ $t('dates') }}: {{ dateRangeText1 }}
                  </div>
                </v-card-text>

                <v-list-item-group v-if="!empty1">
                  <v-list-item
                    v-for="(item, index) in log1"
                    :key="'l1'+index"
                    @click="showdepositdetail(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle class="opacity-6">
                        {{ item.created }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <strong>+{{ item.remark_money }}</strong>
                        {{ $store.state.agentinfo.currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <v-chip
                        x-small
                        color="green lighten-5"
                        text-color="green"
                      >
                        {{ $t('yiwancheng') }}
                      </v-chip>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list-item-group>

                <div
                  v-if="log1.length==0"
                  class="text-center text-body-2 opacity-3 pa-10"
                >
                  <v-icon
                    x-large
                    class="my-2"
                  >
                    mdi-package-variant
                  </v-icon>
                  <div>
                    {{ $t('zanwushuju') }}
                  </div>
                </div>
                <div
                  v-if="(page1-1)<pagecount1"
                  class="text-center my-3"
                  @click="depositlog"
                >
                  <small>{{ $t('jiazaigengduo') }}</small>
                </div>
              </v-tab-item>

              <v-tab-item value="tabrecord-2">
                <v-divider class="opacity-3" />
                <v-card-text class="grey lighten-5 py-2">
                  <div
                    v-if="typeof($route.query.id) == 'undefined'"
                    class="py-2"
                  >
                    <v-row dense>
                      <v-col
                        cols="5"
                        class="pr-0"
                      >
                        <v-select
                          v-model="searchtype"
                          filled
                          dense
                          rounded
                          :items="types"
                          hide-details="auto"
                          no-data-text=""
                          class="rounded-r-0 text-caption"
                        />
                      </v-col>
                      <v-col
                        cols="7"
                        class="pl-0"
                      >
                        <v-text-field
                          v-model="searchmember"
                          filled
                          dense
                          rounded
                          clearable
                          no-data-text=""
                          hide-details="auto"
                          append-icon="mdi-magnify"
                          :placeholder="$t('sousuo_tip')"
                          class="rounded-l-0 text-caption"
                          @click:append="search2"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-chip-group
                    mandatory
                    color="orange"
                  >
                    <v-chip
                      small
                      @click="changeDate2(29)"
                    >
                      {{ $t('dates_30') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate2(6)"
                    >
                      {{ $t('dates_7') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate2(0)"
                    >
                      {{ $t('dates_today') }}
                    </v-chip>

                    <v-spacer />

                    <v-chip
                      small
                      @click="select_date2 = !select_date2"
                    >
                      <v-icon x-small>
                        mdi-filter
                      </v-icon>
                      {{ $t('dates_shaixuan') }}
                    </v-chip>
                  </v-chip-group>
                  <div class="text-right text-caption opacity-5">
                    {{ $t('dates') }}: {{ dateRangeText2 }}
                  </div>
                </v-card-text>

                <v-list-item-group v-if="!empty2">
                  <v-list-item
                    v-for="(item, index) in log2"
                    :key="'l2'+index"
                    @click="showreceivedetail(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle class="opacity-6">
                        {{ item.created }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon small>
                          mdi-account
                        </v-icon>
                        {{ item.from_role }}-{{ item.member_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-subtitle>
                        <div class="text-right">
                          + <strong>{{ item.amount }}</strong>
                          <div>
                            <v-chip
                              v-if="item.status==0"
                              x-small
                              color="lighten-5"
                            >
                              {{ $t('chulizhong') }}
                            </v-chip>
                            <v-chip
                              v-if="item.status==1"
                              x-small
                              color="green lighten-5"
                              text-color="green"
                            >
                              {{ $t('yiwancheng') }}
                            </v-chip>
                            <v-chip
                              v-if="item.status==-1"
                              x-small
                              color="red lighten-5"
                              text-color="red"
                            >
                              {{ $t('shibai') }}
                            </v-chip>
                          </div>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>

                <div
                  v-if="log2.length==0"
                  class="text-center text-body-2 opacity-3 pa-10"
                >
                  <v-icon
                    x-large
                    class="my-2"
                  >
                    mdi-package-variant
                  </v-icon>
                  <div>
                    {{ $t('zanwushuju') }}
                  </div>
                </div>
                <div
                  v-if="(page2-1)<pagecount2"
                  class="text-center my-3"
                  @click="receivelog"
                >
                  <small>{{ $t('gengduo_jiazai') }}</small>
                </div>
              </v-tab-item>

              <v-tab-item value="tabrecord-3">
                <v-divider class="opacity-3" />
                <v-card-text class="grey lighten-5 py-2">
                  <div
                    v-if="typeof($route.query.id) == 'undefined'"
                    class="py-2"
                  >
                    <v-row dense>
                      <v-col
                        cols="5"
                        class="pr-0"
                      >
                        <v-select
                          v-model="searchtype"
                          filled
                          dense
                          rounded
                          :items="types"
                          hide-details="auto"
                          class="rounded-r-0 text-caption"
                        />
                      </v-col>
                      <v-col
                        cols="7"
                        class="pl-0"
                      >
                        <v-text-field
                          v-model="searchmember"
                          filled
                          dense
                          rounded
                          clearable
                          no-data-text=""
                          hide-details="auto"
                          append-icon="mdi-magnify"
                          :placeholder="$t('sousuo_tip')"
                          class="rounded-l-0 text-caption"
                          @click:append="search3"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-chip-group
                    mandatory
                    color="orange"
                  >
                    <v-chip
                      small
                      @click="changeDate3(29)"
                    >
                      {{ $t('dates_30') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate3(6)"
                    >
                      {{ $t('dates_7') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate3(0)"
                    >
                      {{ $t('dates_today') }}
                    </v-chip>

                    <v-spacer />

                    <v-chip
                      small
                      @click="select_date3 = !select_date3"
                    >
                      <v-icon x-small>
                        mdi-filter
                      </v-icon>
                      {{ $t('dates_shaixuan') }}
                    </v-chip>
                  </v-chip-group>
                  <div class="text-right text-caption opacity-5">
                    {{ $t('dates') }}: {{ dateRangeText3 }}
                  </div>
                </v-card-text>

                <v-list-item-group v-if="!empty3">
                  <v-list-item
                    v-for="(item, index) in log3"
                    :key="'l3'+index"
                    @click="showsenddetail(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle class="opacity-6">
                        {{ item.created }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon small>
                          mdi-account
                        </v-icon>
                        {{ item.to_role }}-{{ item.member_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-subtitle>
                        <div class="text-right">
                          - <strong>{{ item.amount }}</strong>
                          <div>
                            <v-chip
                              v-if="item.status==0"
                              x-small
                              color="lighten-5"
                            >
                              {{ $t('chulizhong') }}
                            </v-chip>
                            <v-chip
                              v-if="item.status==1"
                              x-small
                              color="green lighten-5"
                              text-color="green"
                            >
                              {{ $t('yiwancheng') }}
                            </v-chip>
                            <v-chip
                              v-if="item.status==-1"
                              x-small
                              color="red lighten-5"
                              text-color="red"
                            >
                              {{ $t('shibai') }}
                            </v-chip>
                          </div>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>

                <div
                  v-if="log3.length==0"
                  class="text-center text-body-2 opacity-3 pa-10"
                >
                  <v-icon
                    x-large
                    class="my-2"
                  >
                    mdi-package-variant
                  </v-icon>
                  <div>
                    {{ $t('zanwushuju') }}
                  </div>
                </div>
                <div
                  v-if="(page3-1)<pagecount3"
                  class="text-center my-3"
                  @click="sendlog"
                >
                  <small>{{ $t('gengduo_jiazai') }}</small>
                </div>
              </v-tab-item>

              <v-tab-item value="tabrecord-4">
                <v-divider class="opacity-3" />
                <v-card-text class="grey lighten-5 py-2">
                  <v-chip-group
                    mandatory
                    color="orange"
                  >
                    <v-chip
                      small
                      @click="changeDate4(29)"
                    >
                      {{ $t('dates_30') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate4(6)"
                    >
                      {{ $t('dates_7') }}
                    </v-chip>
                    <v-chip
                      small
                      @click="changeDate4(0)"
                    >
                      {{ $t('dates_today') }}
                    </v-chip>

                    <v-spacer />

                    <v-chip
                      small
                      @click="select_date4 = !select_date4"
                    >
                      <v-icon x-small>
                        mdi-filter
                      </v-icon>
                      {{ $t('dates_shaixuan') }}
                    </v-chip>
                  </v-chip-group>
                  <div class="text-right text-caption opacity-5">
                    {{ $t('dates') }}: {{ dateRangeText4 }}
                  </div>
                </v-card-text>

                <v-list-item-group v-if="!empty4">
                  <v-list-item disabled>
                    <v-list-item-content>
                      <v-list-item-action-text>
                        {{ $t('youxi_shijian') }}
                      </v-list-item-action-text>
                    </v-list-item-content>

                    <v-list-item-content class="text-center">
                      <v-list-item-action-text>
                        {{ $t('youxi_touzhu') }}
                      </v-list-item-action-text>
                    </v-list-item-content>

                    <v-list-item-content class="text-center">
                      <v-list-item-action-text>
                        {{ $t('youxi_mingcheng') }}
                      </v-list-item-action-text>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        {{ $t('youxi_paicai') }}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider class="opacity-3" />

                  <v-list-item
                    v-for="(item, index) in log4"
                    :key="'l4'+index"
                    @click="showbetlogdetail(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle class="opacity-6">
                        {{ item.bet_time }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content class="text-center">
                      <v-list-item-subtitle>
                        {{ item.bet_amount }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-content class="text-center">
                      <v-list-item-action-text class="opacity-6">
                        {{ item.game_name }}
                      </v-list-item-action-text>
                    </v-list-item-content>

                    <v-list-item-action class="text-right">
                      <v-list-item-subtitle>
                        <span
                          v-if="item.win_amount>=item.bet_amount"
                          class="green--text"
                        >{{ item.win_amount }}</span>
                        <span
                          v-else
                          class="red--text"
                        >{{ item.win_amount }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>

                <div
                  v-if="log4.length==0"
                  class="text-center text-body-2 opacity-3 pa-10"
                >
                  <v-icon
                    x-large
                    class="my-2"
                  >
                    mdi-package-variant
                  </v-icon>
                  <div>
                    {{ $t('zanwushuju') }}
                  </div>
                </div>
                <div
                  v-if="(page4-1)<pagecount4"
                  class="text-center my-3"
                  @click="betlog"
                >
                  <small class="opacity-3">{{ $t('gengduo_jiazai') }}</small>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>

          <v-card-actions />
        </v-card>
      </div>
    </v-container>

    <template v-if="detail_deposit">
      <DetailDeposit
        :detail="deposit_detail_item"
        @close_detaildeposit="close_detaildeposit"
      />
    </template>

    <template v-if="detail_transfer">
      <DetailTransfer
        :detail="transfer_detail_item"
        @close_detailtransfer="close_detailtransfer"
      />
    </template>

    <template v-if="detail_cashout">
      <DetailCashout
        :detail="cashout_detail_item"
        @close_detailcashout="close_detailcashout"
      />
    </template>

    <template v-if="detail_betlog">
      <DetailBetlog
        :detail="betlog_detail_item"
        @close_detailbetlog="close_detailbetlog"
      />
    </template>

    <template v-if="detail_receive">
      <DetailReceive
        :detail="receive_detail_item"
        @close_detailreceive="close_detailreceive"
      />
    </template>

    <template v-if="detail_send">
      <DetailSend
        :detail="send_detail_item"
        @close_detailsend="close_detailsend"
      />
    </template>

    <template>
      <v-bottom-sheet
        v-model="select_date1"
        inset
        max-width="600px"
      >
        <v-sheet
          class="rounded-t-xl pb-10"
        >
          <v-container>
            <v-row
              dense
              align="center"
            >
              <v-col cols="4">
                <v-btn
                  icon
                  @click="select_date1 = false"
                >
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                {{ $t('dates_shaixuan') }}
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="opacity-3" />

          <v-date-picker
            v-model="picker1"
            range
            full-width
            flat
            color="primary"
            no-title
          />

          <div class="px-3 text-center">
            <v-btn
              block
              x-large
              rounded
              color="primary"
              @click="loglist1"
            >
              {{ $t('tijiao') }}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </template>

    <template>
      <v-bottom-sheet
        v-model="select_date2"
        inset
        max-width="600px"
      >
        <v-sheet
          class="rounded-t-xl pb-10"
        >
          <v-container>
            <v-row
              dense
              align="center"
            >
              <v-col cols="4">
                <v-btn
                  icon
                  @click="select_date2 = false"
                >
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                {{ $t('dates_shaixuan') }}
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="opacity-3" />

          <v-date-picker
            v-model="picker2"
            range
            full-width
            flat
            color="primary"
            no-title
          />

          <div class="px-3 text-center">
            <v-btn
              block
              x-large
              rounded
              color="primary"
              @click="loglist2"
            >
              {{ $t('tijiao') }}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </template>

    <template>
      <v-bottom-sheet
        v-model="select_date3"
        inset
        max-width="600px"
      >
        <v-sheet
          class="rounded-t-xl pb-10"
        >
          <v-container>
            <v-row
              dense
              align="center"
            >
              <v-col cols="4">
                <v-btn
                  icon
                  @click="select_date3 = false"
                >
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                {{ $t('dates_shaixuan') }}
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="opacity-3" />

          <v-date-picker
            v-model="picker3"
            range
            full-width
            flat
            color="primary"
            no-title
          />

          <div class="px-3 text-center">
            <v-btn
              block
              x-large
              rounded
              color="primary"
              @click="loglist3"
            >
              {{ $t('tijiao') }}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </template>

    <template>
      <v-bottom-sheet
        v-model="select_date4"
        inset
        max-width="600px"
      >
        <v-sheet
          class="rounded-t-xl pb-10"
        >
          <v-container>
            <v-row
              dense
              align="center"
            >
              <v-col cols="4">
                <v-btn
                  icon
                  @click="select_date4 = false"
                >
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                {{ $t('dates_shaixuan') }}
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="opacity-3" />

          <v-date-picker
            v-model="picker4"
            range
            full-width
            flat
            color="primary"
            no-title
          />

          <div class="px-3 text-center">
            <v-btn
              block
              x-large
              rounded
              color="primary"
              @click="loglist4"
            >
              {{ $t('tijiao') }}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </template>
  </div>
</template>

<script>
import DetailDeposit from '@/views/pages/detailDeposit.vue'
import DetailTransfer from '@/views/pages/detailTransfer.vue'
import DetailCashout from '@/views/pages/detailCashout.vue'
import DetailBetlog from '@/views/pages/detailBetlog.vue'
import DetailReceive from '@/views/pages/detailReceive.vue'
import DetailSend from '@/views/pages/detailSend.vue'
  export default {
    components:{
      DetailDeposit,
      DetailTransfer,
      DetailCashout,
      DetailBetlog,
      DetailReceive,
      DetailSend,
    },
    data: () => ({
      detail_deposit: false,
      detail_transfer: false,
      detail_cashout: false,
      detail_betlog: false,
      detail_receive: false,
      detail_send: false,

      deposit_detail_item: {},
      transfer_detail_item: {},
      cashout_detail_item: {},
      betlog_detail_item: {},
      receive_detail_item: {},
      send_detail_item: {},

      showCharge: false,
      showTrans: false,
      showCash: false,
      showBetlog: false,
      showReceive: false,
      showSend: false,
      tab_record: '',
      
      select_date1: false,
      picker1: [],
      select_date2: false,
      picker2: [],
      select_date3: false,
      picker3: [],
      select_date4: false,
      picker4: [],

      empty1: false,
      empty2: false,
      empty3: false,
      empty4: false,

      page1:1,
      pagecount1:1,
      log1:[],
      choose1:{},
      page2:1,
      pagecount2:1,
      log2:[],
      choose2:{},
      page3:1,
      pagecount3:1,
      log3:[],
      choose3:{},
      page4:1,
      pagecount4:1,
      log4:[],
      choose4:{},

      types:[],
      searchtype: 'agent',
      searchmember: '',
    }),

    computed: {
      dateRangeText1:{
        get: function () {
          return this.$dayjs(this.picker1[0]).format('DD/MM/YYYY')+' ~ '+this.$dayjs(this.picker1[1]).format('DD/MM/YYYY')
        },
        set: function (newVal) {
        }
      },
      dateRangeText2:{
        get: function () {
          return this.$dayjs(this.picker2[0]).format('DD/MM/YYYY')+' ~ '+this.$dayjs(this.picker2[1]).format('DD/MM/YYYY')
        },
        set: function (newVal) {
        }
      },
      dateRangeText3:{
        get: function () {
          return this.$dayjs(this.picker3[0]).format('DD/MM/YYYY')+' ~ '+this.$dayjs(this.picker3[1]).format('DD/MM/YYYY')
        },
        set: function (newVal) {
        }
      },
      dateRangeText4:{
        get: function () {
          return this.$dayjs(this.picker4[0]).format('DD/MM/YYYY')+' ~ '+this.$dayjs(this.picker4[1]).format('DD/MM/YYYY')
        },
        set: function (newVal) {
        }
      }
    },
    watch:{
      dateRangeText1: {
        handler(newVal, oldVal) {
          this.picker1.sort()
        }
      },
      dateRangeText2: {
        handler(newVal, oldVal) {
          this.picker2.sort()
        }
      },
      dateRangeText3: {
        handler(newVal, oldVal) {
          this.picker3.sort()
        }
      },
      dateRangeText4: {
        handler(newVal, oldVal) {
          this.picker4.sort()
        }
      },
    },
    created() {
      this.types = [
        {'text': this.$t('daili'), 'value': 'agent'},
        {'text': this.$t('wanjia'), 'value': 'player'},
      ]

      this.tab_record = this.tabrecord
      this.picker1 = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
      this.picker2 = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
      this.picker3 = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
      this.picker4 = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]

      this.tab_record = 'tabrecord-2'
      this.showTrans = true
      this.showCash = true
      if(this.$route.query.type=='player'){
        this.showBetlog = true
      }
      if(typeof(this.$route.query.type) == "undefined"){
        if(this.$store.state.agentinfo.pid==0){
          this.showCharge = true
        }
      }
    },
    mounted() {
      this.member_id = this.$route.query.id

      if(this.$route.query.type=='player'){
        this.betlog()
      }
      if(typeof(this.$route.query.type) == "undefined"){
        if(this.$store.state.agentinfo.pid==0){
          this.depositlog()
        }
      }
      this.receivelog()
      this.sendlog()
    },
    methods: {
      changeDate1(d){
        this.page1 = 1
        this.pagecount1 = 1
        this.log1 = []
        this.picker1 = [this.$dayjs().subtract(d, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
        this.depositlog()
        this.select_date1 = false
      },
      changeDate2(d){
        this.page2 = 1
        this.pagecount2 = 1
        this.log2 = []
        this.picker2 = [this.$dayjs().subtract(d, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
        this.receivelog()
        this.select_date2 = false
      },
      changeDate3(d){
        this.page3 = 1
        this.pagecount3 = 1
        this.log3 = []
        this.picker3 = [this.$dayjs().subtract(d, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
        this.sendlog()
        this.select_date3 = false
      },
      changeDate4(d){
        this.page4 = 1
        this.pagecount4 = 1
        this.log4 = []
        this.picker4 = [this.$dayjs().subtract(d, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
        this.betlog()
        this.select_date4 = false
      },
      loglist1() {
        this.page1 = 1
        this.pagecount1 = 1
        this.log1 = []
        this.depositlog()
        this.select_date1 = false
      },
      loglist2() {
        this.page2 = 1
        this.pagecount2 = 1
        this.log2 = []
        this.receivelog()
        this.select_date2 = false
      },
      loglist3() {
        this.page3 = 1
        this.pagecount3 = 1
        this.log3 = []
        this.sendlog()
        this.select_date3 = false
      },
      loglist4() {
        this.page4 = 1
        this.pagecount4 = 1
        this.log4 = []
        this.betlog()
        this.select_date4 = false
      },
      depositlog() {
        if(this.page1>this.pagecount1){
          return false
        }
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          page:this.page1,
          startdate:this.picker1[0],
          enddate:this.picker1[1],
        }
        this.$server.depositlog(paramObj).then((response) => {
          if(response.code==200){
            if(response.data.total==0){
              this.empty1 = true
            }
            this.pagecount1 = response.data.last_page
            if(response.data.data.length>0){
              this.empty1 = false
              this.log1.push(...response.data.data)
            }
            ++this.page1
          }else{
            this.$snackbar.error(response.msg)
          }
        })
      },
      receivelog() {
        if(this.page2>this.pagecount2){
          return false
        }
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          page: this.page2,
          startdate: this.picker2[0],
          enddate: this.picker2[1],
          searchtype: this.searchtype,
          keywords: this.searchmember,
          member_id: this.member_id
        }
        this.$server.receivelog(paramObj).then((response) => {
          if(response.code==200){
            if(response.data.total==0){
              this.empty2 = true
            }
            this.pagecount2 = response.data.last_page
            if(response.data.data.length>0){
              this.empty2 = false
              this.log2.push(...response.data.data)
            }
            ++this.page2
          }else{
            this.$snackbar.error(response.msg)
          }
        })
      },
      sendlog() {
        if(this.page3>this.pagecount3){
          return false
        }
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          page: this.page3,
          startdate: this.picker3[0],
          enddate: this.picker3[1],
          searchtype: this.searchtype,
          keywords: this.searchmember,
          member_id: this.member_id
        }
        this.$server.sendlog(paramObj).then((response) => {
          if(response.code==200){
            if(response.data.total==0){
              this.empty3 = true
            }
            this.pagecount3 = response.data.last_page
            if(response.data.data.length>0){
              this.empty3 = false
              this.log3.push(...response.data.data)
            }
            ++this.page3
          }else{
            this.$snackbar.error(response.msg)
          }
        })
      },
      betlog() {
        if(this.page4>this.pagecount4){
          return false
        }
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          page: this.page4,
          startdate: this.picker4[0],
          enddate: this.picker4[1],
          player_id: this.member_id
        }
        this.$server.betlog(paramObj).then((response) => {
          if(response.code==200){
            if(response.data.total==0){
              this.empty4 = true
            }
            this.pagecount4 = response.data.last_page
            if(response.data.data.length>0){
              this.empty4 = false
              this.log4.push(...response.data.data)
            }
            ++this.page4
          }else{
            this.$snackbar.error(response.msg)
          }
        })
      },
      search2() {
        this.page2 = 1
        this.pagecount2 = 1
        this.log2 = []
        this.receivelog()
        this.select_date2 = false
      },
      search3() {
        this.page3 = 1
        this.pagecount3 = 1
        this.log3 = []
        this.sendlog()
        this.select_date3 = false
      },
      showdepositdetail(item) {
        this.deposit_detail_item = item
        this.detail_deposit = true
      },
      showtransferdetail(item) {
        this.transfer_detail_item = item
        this.detail_transfer = true
      },
      showcashoutdetail(item) {
        this.cashout_detail_item = item
        this.detail_cashout = true
      },
      showbetlogdetail(item) {
        this.betlog_detail_item = item
        this.detail_betlog = true
      },
      showreceivedetail(item) {
        this.receive_detail_item = item
        this.detail_receive = true
      },
      showsenddetail(item) {
        this.send_detail_item = item
        this.detail_send = true
      },
      close_detaildeposit() {
        this.detail_deposit = false
      },
      close_detailtransfer() {
        this.detail_transfer = false
      },
      close_detailcashout() {
        this.detail_cashout = false
      },
      close_detailbetlog() {
        this.detail_betlog = false
      },
      close_detailreceive() {
        this.detail_receive = false
      },
      close_detailsend() {
        this.detail_send = false
      },
      goback() {
        this.$router.back()
      },
      doCopy: function (text, ref) {
        text = ''+text//转string
        this.$copyText(text, this.$refs[`${ref}`]).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    },
  }
</script>