<template>
  <v-bottom-sheet
    v-model="detail_betlog"
    inset
    max-width="600px"
  >
    <v-sheet
      class="rounded-t-xl pb-10"
    >
      <v-toolbar
        :ref="'detail'+'4'"
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          @click="detail_betlog = false"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('xiangqing') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-list
        class="py-0 px-3"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('youxi_shijian') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.bet_time }}
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('dingdanbianhao') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.record_id }}
              <v-btn
                small
                icon
                @click="doCopy(detail.record_id, 'detail4')"
              >
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('youxi_touzhu') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.bet_amount }}
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('youxi_mingcheng') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.game_name }}
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('youxi_paicai') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              <span
                v-if="detail.win_amount>=detail.bet_amount"
                class="green--text"
              >{{ detail.win_amount }}</span>
              <span
                v-else
                class="red--text"
              >{{ detail.win_amount }}</span>
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'DetailBetlog',
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    detail_betlog: true,
  }),
  computed: {

  },
  watch:{
    detail_betlog: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if(newVal==false){
          this.$emit('close_detailbetlog')
        }
      }
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    doCopy: function (text, ref) {
      text = ''+text//转string
      this.$copyText(text, this.$refs[`${ref}`]).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('copy_ok'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>